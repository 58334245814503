<template>
  <div class="container">
    <div class="search-box">
      <el-input
        placeholder="输入关键词"
        suffix-icon="el-icon-search"
        class="search-input"
        @keyup.enter.native="clickSearch"
        :clearable="true"
        @clear="search()"
        v-model="form.searchValue"
      >
      </el-input>
    </div>
    <div class="product-box">
      <div class="product-item" v-for="(item, index) of list" :key="index">
        <div class="item-cover">
          <img class="item-img" :src="item.imgUrl" fit="cover" />
        </div>
        <div class="item-desc">
          <p class="item-title" @click="clickPrdtIntr(item)">{{ item.name }}</p>
          <div class="item-btn">
            <span class="item-date">{{ item.createTime }}</span>
            <span class="item-new" @click="createOrderQuickly(item)"
              >快速下单</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="elPagination">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 产品说明 -->
    <el-dialog
      title="产品说明"
      :visible.sync="prdtIntrDrawer"
      :append-to-body="true"
      :show-close="false"
    >
      <p
        style="line-height: 32px; font-size: 16px"
        v-html="prdtIntrContent"
      ></p>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="prdtIntrDrawer = false"
          >知道了</el-button
        >
      </div>
    </el-dialog>
    <!-- 下单 -->
    <apt-create-order
      v-model="showCreateOrder"
      :data="createOrderQuery"
      title="新建订单"
    />
  </div>
</template>

<script>
import newProduct from "@/assets/images/newProduct.svg";
import { productPage, listProduct } from "@/api/product/index";
import _ from "lodash";
export default {
  name: "product",
  data() {
    return {
      newProduct,
      searchList: [], //搜索列表
      showSearchList: false, //是否展示搜索列表
      list: [], // 产品列表
      // 表单
      form: {
        searchValue: "", // 搜索值
        preSearchValue: "", //上一次的搜索值
      },
      pageNum: 1,
      pageSizes: [12, 24, 36],
      pageSize: 12,
      total: 100,
      prdtIntrDrawer: false, //产品介绍弹窗
      prdtIntrContent: "", //产品介绍内容
      createOrderQuery: null, //快速下单弹窗参数
      showCreateOrder: false, //展示快速下单弹窗
    };
  },
  methods: {
    //快速下单
    createOrderQuickly(item) {
      this.createOrderQuery = {
        product: item.productId,
        type: "createOrderQuickly",
      };
      this.showCreateOrder = true;
    },
    //产品介绍
    clickPrdtIntr(item) {
      const { requirement } = item;
      this.prdtIntrContent = requirement;
      this.prdtIntrDrawer = true;
    },
    // 隐藏搜索列表
    hideSearchList() {
      setTimeout(() => {
        //加定时器是为了让关键词列表点击事件先执行再关闭列表
        this.showSearchList = false;
      }, 200);
    },
    // 点击关键词搜索产品列表
    clickKeywordSearch(item) {
      this.form.searchValue = item.name;
      this.pageNum = 1;
      this.pageSize = 10;
      const params = {
        name: this.form.searchValue,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      productPage(params).then((data) => {
        this.loading = false;
        this.list.splice(0, this.list.length, ...data.records);
        this.total = data.total;
      });
    },
    // 搜索关键词列表
    searchKeywordList() {
      const params = {
        name: this.form.searchValue,
      };
      listProduct(params).then((res) => {
        this.searchList = res;
      });
    },
    // 输入搜索
    inputSearch: _.debounce(function () {
      this.searchKeywordList();
    }, 300),
    // 搜索产品
    search() {
      this.form.preSearchValue = this.form.searchValue;
      const params = {
        name: this.form.searchValue,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      productPage(params).then((data) => {
        this.loading = false;
        this.list.splice(0, this.list.length, ...data.records);
        this.total = data.total;
      });
    },
    // 改变页数
    handleCurrentChange(val) {
      this.pageNum = val;
      this.search();
    },
    // 改变每页数量
    handleSizeChange(val) {
      this.pageSize = val;
      this.search();
    },
    // 判断是否新产品
    isNewProduct(params) {
      if (!params.createTime) return false;
      const curTime = new Date().getTime();
      const createTime = new Date(params.createTime).getTime();
      if (curTime > createTime + 7 * 24 * 60 * 60 * 1000) {
        return false;
      } else {
        return true;
      }
    },
    // 点击搜索
    clickSearch() {
      this.pageNum = 1;
      this.search();
    },
  },
  created() {
    this.search();
    this.searchKeywordList();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/elementuiReset.scss";
.container {
  padding: 22px 32px;
  display: flex;
  flex-direction: column;

  .search-box {
    width: 100%;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    .search-input {
      height: 40px;
      width: 30%;
    }
  }

  .product-box {
    margin-top: 22px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    // 列间距
    column-gap: 22px;
    // 行间距
    row-gap: 22px;
    // justify-content: space-between;

    .product-item {
      width: 24%;
      max-width: 24%;
      height: 420px;
      display: flex;
      flex-direction: column;

      .item-cover {
        height: 100%;
        width: 100%;
        background-color: #fff;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        .item-img {
          object-fit: cover;
          width: 100%;
          height: 280px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
      }

      .item-desc {
        padding: 22px 22px;
        height: 140px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #fff;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        font-size: 14px;
        .item-title {
          font-size: 18px;
          font-weight: 600;
          line-height: 32px;
        }
        .item-title:hover {
          color: #3399ff;
        }
        .item-btn {
          width: 100%;
          // margin-top: 32px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .item-date {
            color: #909399;
          }
          .item-new {
            color: #3399ff;
          }
        }
      }
    }

    // 解决最后一行列数不够展示逻辑
    .product-box:not(:nth-child(4n)) {
      margin-right: calc(4% / 3);
    }

    // 鼠标移至显示阴影效果
    .product-item:hover {
      cursor: pointer;
      box-shadow: #c0c4cc 0px 6px 6px;
      transition: box-shadow 0.3s, border-color 0.3s;
    }
  }
  .elPagination {
    margin: 36px auto;
  }
}
</style>
